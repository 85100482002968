.loading-wrap {
  position: relative;
  min-height: 300px;
  overflow: hidden;
}
.loading-box {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
}

@import-normalize;
@import '~quill/dist/quill.snow.css';
@import '~antd/dist/antd.css';
@import './assets/iconfont/iconfont.css';

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-wrap {
  background: #f6f6f6;
  > .page-container {
    min-height: calc(100vh - 172px);
    margin: 0 auto;
    width: 1000px;
    padding: 30px 0 30px 0;
    /*padding: 30px 0 60px 0;*/
    box-sizing: border-box;
  }
}

.empty-wrap {
  padding: 120px 0;
}

#home-page {
  width: 100%;
  background-color: #fff;
}

.quick-start h3 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 40px;
  color: #fff;
}

/* ------ sign ------ */
.sign {
  width: 72%;
  height: 100%;
  margin: 0 auto;
  color: #fff;
  .box {
    background: #fff;
    height: 476px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    display: flex;
    .left {
      width: 45%;
      height: 100%;
      background: url('./assets/intro.jpg') no-repeat;
      box-sizing: border-box;
      padding-top: 36%;
      padding-left: 26px;
      font-weight: 200;
    }
    .right {
      color: #333;
      width: 55%;
      box-sizing: border-box;
      padding: 30px 40px;
      label {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}

/** sign tab **/
.sign-tab {
  margin-bottom: 20px;
  padding-left: 10px;
  padding-bottom: 18px;
  border-bottom: 1px solid #cdcdcd;
  .link {
    font-size: 16px;
    margin-right: 30px;
    color: #333;
    text-decoration: none;
    &.active {
      color: #0077ff;
      font-weight: 600;
    }
  }
}
/** sign tab erd **/

.sign-form-wrap {
  padding: 0 10px;
  .forgot-btn {
    font-size: 12px;
    text-align: right;
  }
  .forgot .link {
    color: #0077ff;
    text-decoration: none;
  }
  .btn-wrap {
    margin-top: 80px;
    text-align: center;
    .primary-btn {
      width: 160px;
      height: 40px;
      /* line-height: 40px; */
      background: #0077ff;
      color: #fff;
      border: 0 none;
      border-radius: 20px;
      cursor: pointer;
      &:disabled {
        background: #cdcdcd;
        cursor: not-allowed;
      }
      &:not(:disabled):hover {
        opacity: 0.7;
      }
    }
  }
  .agree-with-terms {
    padding-top: 10px;
    input {
      vertical-align: middle;
    }
    span {
      color: #666;
      font-size: 12px;
    }
  }
}
/* ------ sign end ------ */

/** side menu box start **/
.side-menu-box {
  width: 100%;
  .box-header {
    background: rgb(0, 119, 255);
    height: 50px;
    line-height: 50px;
    border-radius: 2px 2px 0 0;
    h3 {
      font-size: 18px;
      font-weight: 600;
      color: #fff;
      margin-left: 12px;
    }
  }
  .box-content {
    background: #fff;
    border: 1px solid #eee;
    ul {
      padding: 20px 0;
      margin-bottom: 0;
      li {
        margin-bottom: 10px;
        .link {
          display: block;
          padding: 4px 0 4px 12px;
          color: #333;
          text-decoration: none;
          font-size: 16px;
          &.active, &:hover {
            color: #47a1ff;
          }
        }
      }
    }
  }
}
/** side menu box end **/

/** ---- share btn ----**/
.share-btn {
  color: #666;
  font-size: 14px;
}
